import {
  ActivityTypeData,
  ActivityTypeSchedulingDataEnum,
  DataPermissionTypeDataEnum,
} from '@ysura/common';

import {
  parseCommentTypeConnection,
  parseTemplateData,
} from '@/services/graphql/parsers';
import { parseTopicConnection } from '@/services/graphql/parsers/topic/topicParser';
import {
  convertedActivityTypeBaseType,
  convertedState,
} from '@/services/graphql/parsers/utils';
import { ActivityType, ActivityTypeConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseActivityTypeData = (
  activityType?: DeepPartial<ActivityType>
): ActivityTypeData => {
  return {
    id: activityType?.id,
    oid: activityType?.oid,
    name: activityType?.name,
    state: convertedState(activityType?.state),
    baseType: convertedActivityTypeBaseType(activityType?.baseType),
    minAttendeeCount: activityType?.minAttendeeCount,
    maxAttendeeCount: activityType?.maxAttendeeCount,
    schedulingType:
      activityType?.schedulingType as unknown as ActivityTypeSchedulingDataEnum,
    timeIsOptional: activityType?.timeIsOptional,
    hideTimeForClosedActivity: activityType?.hideTimeForClosedActivity,
    attendeeScreenSharingAllowed: activityType?.attendeeScreenSharingAllowed,
    allowCoOrganizers: activityType?.allowCoOrganizers,
    defaultDuration: activityType?.defaultDuration,
    daysInPastForModification: activityType?.daysInPastForModification,
    topics: activityType?.topics
      ? parseTopicConnection(activityType.topics)
      : undefined,
    emailInvitationTemplate: activityType?.emailInvitationTemplate
      ? parseTemplateData(activityType?.emailInvitationTemplate)
      : undefined,
    faxInvitationTemplate: activityType?.faxInvitationTemplate
      ? parseTemplateData(activityType?.faxInvitationTemplate)
      : undefined,
    commentTypes: parseCommentTypeConnection(activityType?.commentTypes),
    permissions:
      activityType?.dataPermissions?.map(
        (p) => p?.type as unknown as DataPermissionTypeDataEnum
      ) ?? [],
  };
};

export const parseActivityTypeConnection = (
  activityTypeConnection?: DeepPartial<ActivityTypeConnection>
): Array<ActivityTypeData> => {
  const rawActivityType = activityTypeConnection?.edges ?? [];
  const mappedActivityTypes = rawActivityType
    .map((edge) => edge?.node ?? null)
    .map((activityType) =>
      activityType ? parseActivityTypeData(activityType) : null
    );

  return filterNonNull(mappedActivityTypes);
};
