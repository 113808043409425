import { Button as MuiButton, Dialog, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  AttendeeData,
  CommunicationDataBaseTypeDataEnum,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextOrLoader } from '@/components/Button/TextOrLoader';
import { useNotification } from '@/hooks';
import {
  useGetPersonAvailableEmailsOrFaxesQuery,
  useGetSystemConfigurationQuery,
  useSendInvitationMutation,
} from '@/services/graphql/hooks';
import {
  parsePersonConsents,
  parsePersonData,
  parseSystemConfigurationData,
} from '@/services/graphql/parsers';
import { CommunicationDataBaseType } from '@/services/graphql/types';
import { emailRegex } from '@/utils/regex';
import { InvitationSkeletonDialogContent } from '@/views/Interaction/Remote/Invitation';

import {
  CommunicationValue,
  InvitationDialogContent,
} from './InvitationDialogContent';

type InvitationDialogProps = {
  activity: ActivityData;
  isOpen: boolean;
  onClose: VoidFunction;
  attendee?: AttendeeData;
};

export const isEmailValid = (email: string) => emailRegex.test(email);

export const InvitationDialog = ({
  activity,
  attendee,
  isOpen,
  onClose,
}: InvitationDialogProps) => {
  const { t } = useTranslation();
  const { toast } = useNotification();

  const [selectedItem, setSelectedItem] = useState<CommunicationValue>({
    type: CommunicationDataBaseTypeDataEnum.EMAIL,
    value: '',
  });

  const [isCustomFieldValid, setIsCustomFieldValid] = useState(true);

  const [sendInvitationMutation, { loading: isSendingInvitationLoading }] =
    useSendInvitationMutation();

  const {
    data: systemConfigurationData,
    loading: isSystemConfigurationLoading,
  } = useGetSystemConfigurationQuery();
  const systemConfiguration = systemConfigurationData?.systemConfiguration
    ? parseSystemConfigurationData(systemConfigurationData.systemConfiguration)
    : null;

  const { data, loading } = useGetPersonAvailableEmailsOrFaxesQuery({
    variables: { id: attendee?.person?.id ?? '' },
  });
  const availableCommunicationData: CommunicationValue[] = [];
  const person = parsePersonData(data?.person);
  person?.communicationData?.forEach((each) => {
    if (each.value) {
      availableCommunicationData.push({
        value: each.value,
        type: each.communicationDataType?.baseType,
      });
    }
  });
  person?.employments?.forEach(
    (employment) =>
      employment.communicationData?.forEach((each) => {
        if (each.value) {
          availableCommunicationData.push({
            value: each.value,
            type: each.communicationDataType?.baseType,
          });
        }
      })
  );

  const personConsents = parsePersonConsents(data?.person);
  personConsents?.forEach(
    (each) =>
      each.consentItemValues?.forEach(
        (item) =>
          item.communicationDataValues?.forEach((value) => {
            if (
              value?.value &&
              (value?.communicationDataType?.baseType ===
                CommunicationDataBaseTypeDataEnum.EMAIL ||
                value?.communicationDataType?.baseType ===
                  CommunicationDataBaseTypeDataEnum.FAX)
            ) {
              availableCommunicationData.push({
                value: value.value,
                type: value.communicationDataType?.baseType,
              });
            }
          })
      )
  );

  const handleInvitationSend = async () => {
    if (!isCustomFieldValid) {
      toast?.({
        type: 'warning',
        message: t('pages.interaction.remote.invalidInputWarning', {
          type: selectedItem.type?.toLowerCase(),
        }),
      });

      return;
    }
    await sendInvitation();
  };

  const sendInvitation = async () => {
    await sendInvitationMutation({
      variables: {
        input: {
          oid: activity.oid ?? '',
          type: selectedItem.type as CommunicationDataBaseType,
          country: systemConfiguration?.defaultCountry?.oid,
          receivers: [
            {
              attendeeId: attendee?.oid ?? '',
              value: selectedItem.value ?? '',
            },
          ],
        },
      },
      onCompleted() {
        toast?.({
          type: 'success',
          message: t(
            'pages.interaction.remote.inviteAttendeeViaEmailOrFaxSuccess'
          ),
        });
        onClose();
      },
      onError() {
        toast?.({
          type: 'error',
          message: t(
            'pages.interaction.remote.inviteAttendeeViaEmailOrFaxFailure'
          ),
        });
      },
    });
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xs">
      {loading || isSystemConfigurationLoading ? (
        <InvitationSkeletonDialogContent />
      ) : (
        <InvitationDialogContent
          availableCommunicationData={availableCommunicationData}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          isCustomFieldValid={isCustomFieldValid}
          setIsCustomFieldValid={setIsCustomFieldValid}
          handleInvitationSend={handleInvitationSend}
          defaultCountry={systemConfiguration?.defaultCountry}
        />
      )}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('components.common.cancel')}
        </Button>
        <Button
          disabled={isSendingInvitationLoading}
          variant="contained"
          onClick={handleInvitationSend}
        >
          <TextOrLoader
            isLoading={isSendingInvitationLoading}
            text={t('pages.interaction.remote.send')}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
