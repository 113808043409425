import { gql } from '@apollo/client';

export const getPersonAvailableEmailsOrFaxes = gql`
  query getPersonAvailableEmailsOrFaxes($id: ID!) {
    person(id: $id) {
      id
      communicationData(
        filter: { communicationDataType: { baseType: { _in: [EMAIL, FAX] } } }
      ) {
        edges {
          node {
            value
            communicationDataType {
              baseType
            }
          }
        }
      }
      employments(filter: { state: { _eq: ACTIVE } }) {
        filteredCount
        edges {
          node {
            id
            oid
            communicationData(
              filter: {
                communicationDataType: { baseType: { _in: [EMAIL, FAX] } }
              }
            ) {
              edges {
                node {
                  value
                  communicationDataType {
                    baseType
                  }
                }
              }
            }
          }
        }
      }
      consents(filter: { consentType: { state: { _eq: ACTIVE } } }) {
        edges {
          node {
            id
            oid
            consentItemValues {
              communicationDataValues {
                value
                consentCommunicationDataType {
                  oid
                  communicationDataType {
                    id
                    baseType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
