import { gql } from '@apollo/client';

export const getActivityById = gql`
  query getActivityById($id: ID!) {
    activity(id: $id) {
      id
      oid
      state
      status
      startDate
      startDateTime
      endDate
      endDateTime
      confirmed
      sealed
      activityType {
        id
        oid
        name
        baseType
        daysInPastForModification
        defaultDuration
        schedulingType
        minAttendeeCount
        maxAttendeeCount
        attendeeScreenSharingAllowed
        allowCoOrganizers
        hideTimeForClosedActivity
        emailInvitationTemplate {
          id
        }
        faxInvitationTemplate {
          id
        }
        topics {
          edges {
            node {
              id
              oid
              name
            }
          }
        }
        commentTypes {
          edges {
            node {
              id
              oid
              name
              state
              dataPermissions {
                type
              }
            }
          }
        }
      }
      organization {
        id
        oid
        name
        addresses(
          filter: {
            addressType: { _eq: STANDARD }
            address: { state: { _eq: ACTIVE } }
          }
        ) {
          edges {
            node {
              address {
                id
                oid
                addressLine1
                addressLine2
                addressLine3
                city
                postalCode
              }
            }
          }
        }
        rootOrganization {
          id
          name
        }
      }
      organizer {
        id
        oid
        firstName
        middleName
        lastName
        prefixAcademicTitle {
          id
          oid
          name
          state
        }
        postfixAcademicTitle {
          id
          oid
          name
          state
        }
      }
      organizationalUnit {
        id
        oid
      }
      attendees {
        edges {
          node {
            id
            oid
            isGuest
            guestName
            person {
              id
              oid
              firstName
              middleName
              lastName
              customerReference
              gender
              prefixAcademicTitle {
                id
                oid
                name
                state
              }
              postfixAcademicTitle {
                id
                oid
                name
                state
              }
              personTypes {
                edges {
                  node {
                    id
                    oid
                    name
                  }
                }
              }
              specialities {
                edges {
                  node {
                    speciality {
                      id
                      oid
                      name
                    }
                  }
                }
              }
              specialityGroup {
                id
                oid
                name
              }
              employments(
                filter: { state: { _eq: ACTIVE } }
                orderBy: { sortCode: ASC }
              ) {
                edges {
                  node {
                    id
                    oid
                    sortCode
                    function {
                      id
                      oid
                      name
                    }
                    department {
                      id
                      oid
                      name
                    }
                    communicationData {
                      edges {
                        node {
                          oid
                          value
                          communicationDataType {
                            id
                            oid
                            baseType
                            name
                          }
                        }
                      }
                    }
                    addresses(filter: { address: { state: { _eq: ACTIVE } } }) {
                      edges {
                        node {
                          addressType
                          address {
                            id
                            oid
                            addressLine1
                            addressLine1
                            addressLine1
                            city
                            postalCode
                            state
                          }
                        }
                      }
                    }
                    organization {
                      id
                      oid
                      name
                      addresses(
                        filter: { address: { state: { _eq: ACTIVE } } }
                      ) {
                        edges {
                          node {
                            addressType
                            address {
                              id
                              oid
                              addressLine1
                              addressLine1
                              addressLine1
                              city
                              postalCode
                              state
                            }
                          }
                        }
                      }
                      communicationData {
                        edges {
                          node {
                            oid
                            value
                            communicationDataType {
                              id
                              oid
                              baseType
                              name
                            }
                          }
                        }
                      }
                      rootOrganization {
                        id
                        oid
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      coOrganizers {
        edges {
          node {
            id
            oid
            person {
              id
              oid
              firstName
              middleName
              lastName
              customerReference
              gender
            }
            organizationalUnit {
              id
              oid
              name
              state
            }
          }
        }
      }
      media {
        edges {
          node {
            oid
            media {
              id
              oid
              name
              description
              mediaType
              thumbnailDocument {
                id
                oid
              }
              latestVersion {
                oid
                version
                document {
                  id
                  oid
                }
              }
            }
            shownVersion {
              oid
              document {
                id
                oid
              }
            }
            reaction
            keyMessages {
              reaction
              keyMessage {
                id
                oid
                name
                description
                topics {
                  edges {
                    node {
                      oid
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
      topics {
        edges {
          node {
            id
            oid
            name
            state
          }
        }
      }
      comments(
        filter: { commentType: { state: { _in: [ACTIVE, INACTIVE] } } }
      ) {
        edges {
          node {
            id
            oid
            content
            commentType {
              id
              oid
              name
              dataPermissions {
                type
              }
            }
            targetType
            state
            dataPermissions {
              type
            }
          }
        }
      }
      promotionalMaterials {
        edges {
          node {
            oid
            promotionalMaterial {
              id
              oid
              name
              type
              signatureRequired
            }
            quantity
            requestedQuantity
            stock {
              oid
              type
              promotionalMaterialBatch {
                oid
                expiryDate
                name
              }
            }
          }
        }
      }
      sampleRequestForm {
        id
        oid
        document {
          id
          oid
          name
        }
      }
      callDetails {
        edges {
          node {
            oid
            startDateTime
            endDateTime
            customerReference
            callerPhoneNumber
            calleePhoneNumber
            callType
            callOutcome {
              id
              oid
            }
          }
        }
      }
    }
  }
`;
