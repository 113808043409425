import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type SendInvitationMutationVariables = Types.Exact<{
  input: Types.InvitationActivityInput;
}>;

export type SendInvitationMutation = {
  __typename?: 'Mutation';
  sendInvitation?: { __typename?: 'Activity'; id: string } | null;
};

export type SendInvitationMutationFn = Apollo.MutationFunction<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >(Operations.sendInvitation, options);
}
export type SendInvitationMutationHookResult = ReturnType<
  typeof useSendInvitationMutation
>;
export type SendInvitationMutationResult =
  Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;
