import { Skeleton, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DialogContentStyle, TinyStack } from './InvitationDialogLayout';

export const InvitationSkeletonDialogContent = () => {
  const { t } = useTranslation();

  return (
    <DialogContentStyle data-testid="invitation-skeleton-dialog">
      <Typography variant="h5">
        {t('pages.interaction.remote.sendInvitationTo')}
      </Typography>

      <TinyStack>
        <Typography>
          <Skeleton height={36} />
        </Typography>

        <Typography>
          <Skeleton height={36} />
        </Typography>

        <Typography>
          <Skeleton height={36} />
        </Typography>
      </TinyStack>

      <TextField
        data-testid="email-text-field"
        label={<Skeleton width={160} height={20} />}
        disabled={true}
      />
    </DialogContentStyle>
  );
};
