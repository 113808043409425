import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetActivityByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetActivityByIdQuery = {
  __typename?: 'Query';
  activity?: {
    __typename?: 'Activity';
    id: string;
    oid: string;
    state: Types.State;
    status: Types.ActivityStatus;
    startDate?: string | null;
    startDateTime?: string | null;
    endDate?: string | null;
    endDateTime?: string | null;
    confirmed: boolean;
    sealed: boolean;
    activityType: {
      __typename?: 'ActivityType';
      id: string;
      oid: string;
      name: string;
      baseType: Types.ActivityTypeBaseType;
      daysInPastForModification?: number | null;
      defaultDuration: number;
      schedulingType: Types.ActivityTypeScheduling;
      minAttendeeCount: number;
      maxAttendeeCount: number;
      attendeeScreenSharingAllowed: boolean;
      allowCoOrganizers: boolean;
      hideTimeForClosedActivity: boolean;
      emailInvitationTemplate?: { __typename?: 'Template'; id: string } | null;
      faxInvitationTemplate?: { __typename?: 'Template'; id: string } | null;
      topics: {
        __typename?: 'TopicConnection';
        edges?: Array<{
          __typename?: 'TopicEdge';
          node?: {
            __typename?: 'Topic';
            id: string;
            oid: string;
            name: string;
          } | null;
        }> | null;
      };
      commentTypes: {
        __typename?: 'CommentTypeConnection';
        edges?: Array<{
          __typename?: 'CommentTypeEdge';
          node?: {
            __typename?: 'CommentType';
            id: string;
            oid: string;
            name: string;
            state: Types.State;
            dataPermissions: Array<{
              __typename?: 'DataPermission';
              type: Types.DataPermissionType;
            }>;
          } | null;
        }> | null;
      };
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      oid: string;
      name?: string | null;
      addresses: {
        __typename?: 'EntityAddressConnection';
        edges?: Array<{
          __typename?: 'EntityAddressEdge';
          node?: {
            __typename?: 'EntityAddress';
            address: {
              __typename?: 'Address';
              id: string;
              oid: string;
              addressLine1?: string | null;
              addressLine2?: string | null;
              addressLine3?: string | null;
              city: string;
              postalCode: string;
            };
          } | null;
        }> | null;
      };
      rootOrganization?: {
        __typename?: 'Organization';
        id: string;
        name?: string | null;
      } | null;
    };
    organizer: {
      __typename?: 'Person';
      id: string;
      oid: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      prefixAcademicTitle?: {
        __typename?: 'AcademicTitle';
        id: string;
        oid: string;
        name?: string | null;
        state: Types.State;
      } | null;
      postfixAcademicTitle?: {
        __typename?: 'AcademicTitle';
        id: string;
        oid: string;
        name?: string | null;
        state: Types.State;
      } | null;
    };
    organizationalUnit?: {
      __typename?: 'OrganizationalUnit';
      id: string;
      oid: string;
    } | null;
    attendees: {
      __typename?: 'ActivityAttendeeConnection';
      edges?: Array<{
        __typename?: 'ActivityAttendeeEdge';
        node?: {
          __typename?: 'ActivityAttendee';
          id: string;
          oid: string;
          isGuest: boolean;
          guestName?: string | null;
          person?: {
            __typename?: 'Person';
            id: string;
            oid: string;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            customerReference?: string | null;
            gender?: Types.Gender | null;
            prefixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              id: string;
              oid: string;
              name?: string | null;
              state: Types.State;
            } | null;
            postfixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              id: string;
              oid: string;
              name?: string | null;
              state: Types.State;
            } | null;
            personTypes: {
              __typename?: 'PersonTypeConnection';
              edges?: Array<{
                __typename?: 'PersonTypeEdge';
                node?: {
                  __typename?: 'PersonType';
                  id: string;
                  oid: string;
                  name?: string | null;
                } | null;
              }> | null;
            };
            specialities: {
              __typename?: 'PersonSpecialityConnection';
              edges?: Array<{
                __typename?: 'PersonSpecialityEdge';
                node?: {
                  __typename?: 'PersonSpeciality';
                  speciality?: {
                    __typename?: 'Speciality';
                    id: string;
                    oid: string;
                    name?: string | null;
                  } | null;
                } | null;
              }> | null;
            };
            specialityGroup?: {
              __typename?: 'SpecialityGroup';
              id: string;
              oid: string;
              name?: string | null;
            } | null;
            employments: {
              __typename?: 'EmploymentConnection';
              edges?: Array<{
                __typename?: 'EmploymentEdge';
                node?: {
                  __typename?: 'Employment';
                  id: string;
                  oid: string;
                  sortCode?: number | null;
                  function?: {
                    __typename?: 'EmployeeFunction';
                    id: string;
                    oid: string;
                    name?: string | null;
                  } | null;
                  department?: {
                    __typename?: 'EmployeeDepartment';
                    id: string;
                    oid: string;
                    name?: string | null;
                  } | null;
                  communicationData: {
                    __typename?: 'CommunicationDataConnection';
                    edges?: Array<{
                      __typename?: 'CommunicationDataEdge';
                      node?: {
                        __typename?: 'CommunicationData';
                        oid?: string | null;
                        value?: string | null;
                        communicationDataType: {
                          __typename?: 'CommunicationDataType';
                          id: string;
                          oid: string;
                          baseType?: Types.CommunicationDataBaseType | null;
                          name?: string | null;
                        };
                      } | null;
                    }> | null;
                  };
                  addresses: {
                    __typename?: 'EntityAddressConnection';
                    edges?: Array<{
                      __typename?: 'EntityAddressEdge';
                      node?: {
                        __typename?: 'EntityAddress';
                        addressType: Types.AddressType;
                        address: {
                          __typename?: 'Address';
                          id: string;
                          oid: string;
                          addressLine1?: string | null;
                          city: string;
                          postalCode: string;
                          state: Types.State;
                        };
                      } | null;
                    }> | null;
                  };
                  organization: {
                    __typename?: 'Organization';
                    id: string;
                    oid: string;
                    name?: string | null;
                    addresses: {
                      __typename?: 'EntityAddressConnection';
                      edges?: Array<{
                        __typename?: 'EntityAddressEdge';
                        node?: {
                          __typename?: 'EntityAddress';
                          addressType: Types.AddressType;
                          address: {
                            __typename?: 'Address';
                            id: string;
                            oid: string;
                            addressLine1?: string | null;
                            city: string;
                            postalCode: string;
                            state: Types.State;
                          };
                        } | null;
                      }> | null;
                    };
                    communicationData: {
                      __typename?: 'CommunicationDataConnection';
                      edges?: Array<{
                        __typename?: 'CommunicationDataEdge';
                        node?: {
                          __typename?: 'CommunicationData';
                          oid?: string | null;
                          value?: string | null;
                          communicationDataType: {
                            __typename?: 'CommunicationDataType';
                            id: string;
                            oid: string;
                            baseType?: Types.CommunicationDataBaseType | null;
                            name?: string | null;
                          };
                        } | null;
                      }> | null;
                    };
                    rootOrganization?: {
                      __typename?: 'Organization';
                      id: string;
                      oid: string;
                      name?: string | null;
                    } | null;
                  };
                } | null;
              }> | null;
            };
          } | null;
        } | null;
      }> | null;
    };
    coOrganizers: {
      __typename?: 'ActivityCoOrganizerConnection';
      edges?: Array<{
        __typename?: 'ActivityCoOrganizerEdge';
        node?: {
          __typename?: 'ActivityCoOrganizer';
          id: string;
          oid: string;
          person?: {
            __typename?: 'Person';
            id: string;
            oid: string;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            customerReference?: string | null;
            gender?: Types.Gender | null;
          } | null;
          organizationalUnit?: {
            __typename?: 'OrganizationalUnit';
            id: string;
            oid: string;
            name?: string | null;
            state: Types.State;
          } | null;
        } | null;
      }> | null;
    };
    media: {
      __typename?: 'ActivityShownMediaConnection';
      edges?: Array<{
        __typename?: 'ActivityShownMediaEdge';
        node?: {
          __typename?: 'ActivityShownMedia';
          oid: string;
          reaction: Types.Reaction;
          media: {
            __typename?: 'Media';
            id: string;
            oid: string;
            name: string;
            description?: string | null;
            mediaType?: Types.SupportedMediaType | null;
            thumbnailDocument?: {
              __typename?: 'Document';
              id: string;
              oid: string;
            } | null;
            latestVersion: {
              __typename?: 'MediaVersion';
              oid: string;
              version?: number | null;
              document?: {
                __typename?: 'Document';
                id: string;
                oid: string;
              } | null;
            };
          };
          shownVersion: {
            __typename?: 'MediaVersion';
            oid: string;
            document?: {
              __typename?: 'Document';
              id: string;
              oid: string;
            } | null;
          };
          keyMessages: Array<{
            __typename?: 'ActivityShownMediaKeyMessage';
            reaction: Types.Reaction;
            keyMessage: {
              __typename?: 'KeyMessage';
              id: string;
              oid: string;
              name?: string | null;
              description?: string | null;
              topics: {
                __typename?: 'TopicConnection';
                edges?: Array<{
                  __typename?: 'TopicEdge';
                  node?: {
                    __typename?: 'Topic';
                    oid: string;
                    id: string;
                  } | null;
                }> | null;
              };
            };
          }>;
        } | null;
      }> | null;
    };
    topics: {
      __typename?: 'TopicConnection';
      edges?: Array<{
        __typename?: 'TopicEdge';
        node?: {
          __typename?: 'Topic';
          id: string;
          oid: string;
          name: string;
          state?: Types.State | null;
        } | null;
      }> | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges?: Array<{
        __typename?: 'CommentEdge';
        node?: {
          __typename?: 'Comment';
          id: string;
          oid: string;
          content?: string | null;
          targetType: Types.CommentTargetType;
          state: Types.State;
          commentType: {
            __typename?: 'CommentType';
            id: string;
            oid: string;
            name: string;
            dataPermissions: Array<{
              __typename?: 'DataPermission';
              type: Types.DataPermissionType;
            }>;
          };
          dataPermissions: Array<{
            __typename?: 'DataPermission';
            type: Types.DataPermissionType;
          }>;
        } | null;
      }> | null;
    };
    promotionalMaterials: {
      __typename?: 'GivenPromotionalMaterialConnection';
      edges?: Array<{
        __typename?: 'GivenPromotionalMaterialEdge';
        node?: {
          __typename?: 'GivenPromotionalMaterial';
          oid: string;
          quantity?: number | null;
          requestedQuantity: number;
          promotionalMaterial: {
            __typename?: 'PromotionalMaterial';
            id: string;
            oid: string;
            name: string;
            type: Types.PromotionalMaterialType;
            signatureRequired?: boolean | null;
          };
          stock?: {
            __typename?: 'Stock';
            oid: string;
            type?: Types.StockType | null;
            promotionalMaterialBatch?: {
              __typename?: 'PromotionalMaterialBatch';
              oid: string;
              expiryDate?: string | null;
              name?: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
    };
    sampleRequestForm?: {
      __typename?: 'SampleRequestForm';
      id: string;
      oid: string;
      document?: {
        __typename?: 'Document';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    } | null;
    callDetails: {
      __typename?: 'ActivityCallDetailConnection';
      edges?: Array<{
        __typename?: 'ActivityCallDetailEdge';
        node?: {
          __typename?: 'ActivityCallDetail';
          oid: string;
          startDateTime: string;
          endDateTime: string;
          customerReference?: string | null;
          callerPhoneNumber?: string | null;
          calleePhoneNumber?: string | null;
          callType: Types.CallType;
          callOutcome: { __typename?: 'CallOutcome'; id: string; oid: string };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetActivityByIdQuery__
 *
 * To run a query within a React component, call `useGetActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityByIdQuery,
    GetActivityByIdQueryVariables
  > &
    (
      | { variables: GetActivityByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetActivityByIdQuery, GetActivityByIdQueryVariables>(
    Operations.getActivityById,
    options
  );
}
export function useGetActivityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityByIdQuery,
    GetActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetActivityByIdQuery,
    GetActivityByIdQueryVariables
  >(Operations.getActivityById, options);
}
export function useGetActivityByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityByIdQuery,
    GetActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetActivityByIdQuery,
    GetActivityByIdQueryVariables
  >(Operations.getActivityById, options);
}
export type GetActivityByIdQueryHookResult = ReturnType<
  typeof useGetActivityByIdQuery
>;
export type GetActivityByIdLazyQueryHookResult = ReturnType<
  typeof useGetActivityByIdLazyQuery
>;
export type GetActivityByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityByIdSuspenseQuery
>;
export type GetActivityByIdQueryResult = Apollo.QueryResult<
  GetActivityByIdQuery,
  GetActivityByIdQueryVariables
>;
