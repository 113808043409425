import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommunicationDataBaseTypeDataEnum, CountryData } from '@ysura/common';
import libphonenumber from 'google-libphonenumber';
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useState,
} from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { emailRegex } from '@/utils/regex';

import { DialogContentStyle } from './InvitationDialogLayout';

export interface CommunicationValue {
  value: string | null;
  type?: CommunicationDataBaseTypeDataEnum | string;
}

type InvitationDialogContentProps = {
  availableCommunicationData: Array<CommunicationValue>;
  selectedItem: CommunicationValue;
  setSelectedItem: Dispatch<SetStateAction<CommunicationValue>>;
  isCustomFieldValid: boolean;
  setIsCustomFieldValid: Dispatch<SetStateAction<boolean>>;
  handleInvitationSend: VoidFunction;
  defaultCountry?: CountryData;
};

export const InvitationDialogContent = ({
  availableCommunicationData,
  selectedItem,
  setSelectedItem,
  isCustomFieldValid,
  setIsCustomFieldValid,
  handleInvitationSend,
  defaultCountry,
}: InvitationDialogContentProps) => {
  const { t } = useTranslation();
  const [customSelected, setCustomSelected] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<
    CommunicationDataBaseTypeDataEnum | string
  >(CommunicationDataBaseTypeDataEnum.EMAIL);

  const filteredAvailableCommunicationData = availableCommunicationData.filter(
    (it) => it.type === selectedChannel
  );

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

  const handleChannelChange = (value: string) => {
    setSelectedChannel(() => value);
    setCustomSelected(() => false);
    setSelectedItem({
      type: value,
      value: '',
    });
  };

  const handleItemSelectedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isCustomized = event.target.name === 'customized';
    const channel = isCustomized ? selectedChannel : event.target.name;
    const value = isCustomized ? '' : event.target.value;
    setCustomSelected(() => isCustomized);
    setIsCustomFieldValid(() => !isCustomized);
    setSelectedItem({
      type: channel,
      value: value,
    });
  };

  const handleCustomizedFieldInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = validateInputValue(event.target.value);
    setSelectedItem({
      type: selectedChannel,
      value: value ?? '',
    });
  };

  const validateInputValue = (value?: string) => {
    if (selectedChannel === CommunicationDataBaseTypeDataEnum.EMAIL) {
      setIsCustomFieldValid(() => emailRegex.test(value ?? ''));
    } else if (selectedChannel === CommunicationDataBaseTypeDataEnum.FAX) {
      try {
        const parsedPhone = phoneUtil.parse(
          value,
          defaultCountry?.alpha2Code ?? 'DE'
        );
        const isValid = phoneUtil.isValidNumber(parsedPhone);
        setIsCustomFieldValid(() => isValid);
        if (isValid) {
          value = '+'.concat(
            parsedPhone.getCountryCodeOrDefault().toString(),
            parsedPhone.getNationalNumberOrDefault().toString()
          );
        }
      } catch {
        setIsCustomFieldValid(() => false);
      }
    }

    return value;
  };

  const onEnterKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleInvitationSend();
    }
  };

  const showRadioOptionsChoice = !!availableCommunicationData?.length;

  return (
    <DialogContentStyle>
      <Typography variant="h5">
        {t('pages.interaction.remote.sendInvitationChannel')}
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby="emails"
          value={selectedChannel}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            value: string
          ) => handleChannelChange(value)}
        >
          {[
            CommunicationDataBaseTypeDataEnum.EMAIL,
            CommunicationDataBaseTypeDataEnum.FAX,
          ].map((value: CommunicationDataBaseTypeDataEnum) => {
            return (
              <FormControlLabel
                key={value}
                value={value}
                label={value}
                control={<Radio size="small" />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      {showRadioOptionsChoice && (
        <FormControl>
          <Typography variant="h5">
            {t('pages.interaction.remote.sendInvitationTo')}
          </Typography>
          <RadioGroup
            aria-labelledby="emails"
            value={selectedItem.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleItemSelectedChange(event)
            }
          >
            {filteredAvailableCommunicationData.map(
              (item: CommunicationValue) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    name={item.type}
                    value={item.value}
                    label={item.value}
                    control={<Radio size="small" />}
                  />
                );
              }
            )}
            <FormControlLabel
              checked={customSelected}
              name="customized"
              control={<Radio size="small" />}
              label={
                selectedChannel === CommunicationDataBaseTypeDataEnum.EMAIL
                  ? t('pages.interaction.remote.addNewEmail')
                  : t('pages.interaction.remote.addNewFax')
              }
            />
          </RadioGroup>
        </FormControl>
      )}

      <CustomizedTextField
        data-testid="input-text-field"
        inputRef={(input: HTMLDivElement) => {
          if (input && customSelected) {
            input.focus();
          }
        }}
        value={selectedItem.value}
        error={!isCustomFieldValid}
        helperText={
          !isCustomFieldValid
            ? t('pages.interaction.remote.inputError', {
                type: selectedChannel.toLowerCase(),
              })
            : ''
        }
        label={
          selectedChannel === CommunicationDataBaseTypeDataEnum.EMAIL
            ? t('pages.interaction.remote.email')
            : t('pages.interaction.remote.fax')
        }
        disabled={!customSelected && showRadioOptionsChoice}
        onChange={handleCustomizedFieldInputChange}
        onKeyUp={onEnterKeyUp}
      />
    </DialogContentStyle>
  );
};

const CustomizedTextField = styled(TextField)({
  'input.Mui-disabled': {
    cursor: 'pointer',
  },
});
